import React, { ReactElement } from "react";
import styles from "./styles.module.scss";
import Image from "next/image";

export default function WhyUs(): ReactElement {
    return (
        <section className={` relative ${styles.description}`}>
            <div className="flex items-center pt-20 pb-24 gap-16 flex-col lg:flex-row">
                <div className="w-[300px] h-[270px] lg:w-[548px] lg:h-[465px] relative">
                    <Image
                        src="/camplejeuneapplication/assets/images/waste-2Opt.jpg"
                        layout="fill"
                        alt="waste"
                    />
                </div>
                <div className="text-white max-w-[500px] ">
                    <h3 className="lg:text-3xl text-xl lg:leading-[45px]">
                        Water Contamination at Camp Lejeune
                    </h3>
                    <p className="text-sm lg:text-base leading-9 mt-4 font-light">
                        The water supply at Camp Lejeune was contaminated with
                        high levels of toxic chemicals, including benzene,
                        trichloroethylene (TCE), tetrachloroethylene,
                        perchloroethylene (PCE), and vinyl chloride. These
                        chemicals are known to cause cancer. The contamination
                        occurred through various means, such as leaks from
                        garbage dumps and underground storage tanks that allowed
                        the chemicals to enter the water system. Even though the
                        tanks were shut down in February 1985, those exposed to
                        the toxic water still suffer from severe and sometimes
                        life-threatening illnesses.
                    </p>
                </div>
            </div>
        </section>
    );
}
